<template>
  <div class="Carousel">
    <el-image v-if="bannerList.length == 0"  style="width: 100%; height: 140px" slot="placeholder" src="http://pic1.win4000.com/wallpaper/2019-01-23/5c481007f3822.jpg" fit="cover"></el-image>
    <div v-else>
      <el-carousel height="180px" arrow="never">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image style="width: 100%; height: 100%" :src="item.url" fit="cover" ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
 name: 'Carousel',
  props:{
    bannerList:{
      type:Array,
      default:()=>{
        return [];
      }
    },
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {

    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {},
  /** 监听数据变化   */
  watch: {},
  /**  实例创建完成之后  */
  created() {
  },
  /**  实例被挂载后调用  */
  mounted() {},
}
</script>

<style lang="scss">

.Carousel{

 }
</style>
